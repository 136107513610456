import { useEffect, useMemo, useState } from 'react';

const listen = (scope, handle) => {
  if (scope.addListener) {
    scope.addListener(handle);
    return () => scope.removeListener(handle);
  }
  scope.addEventListener('change', handle);
  return () => scope.removeEventListener('change', handle);
};

const useMatchMedia = (query) => {
  const mediaQuery = useMemo(() => global.matchMedia?.(query), [query]);

  const [matches, setMatches] = useState(false);

  useEffect(() => {
    setMatches(mediaQuery.matches);

    return listen(mediaQuery, () => setMatches(mediaQuery.matches));
  }, [mediaQuery]);

  return matches;
};

export default useMatchMedia;
