import { IconKey } from '@/newComponents/SvgIcon/SvgIcon';
import { ButtonHTMLAttributes } from 'react';
import { css } from 'styled-components';

import { Color } from '@/global/style/variables';
import { addOpacity, OpacityType } from '@/global/utils/colorUtil';

export type LinkColorVariant = Color.Blue | Color.Hallon1 | Color.White;

export type LinkLookingButtonProps = {
  ['data-testid']: string;
  colorVariant?: LinkColorVariant;
  fontSize?: '1.6rem' | '1.4rem';
  iconKey?: IconKey;
  leftIcon?: boolean;
  text: string | React.ReactNode;
  textAlign?: 'left' | 'center' | 'right';
  alignSelf?: 'start' | 'center' | 'end' | 'auto';
};

export type ExtendedLinkButtonProps = LinkLookingButtonProps & ButtonHTMLAttributes<HTMLButtonElement>;

export const linkVariant = {
  [Color.Blue]: css`
    color: ${Color.Blue};

    &:hover {
      background-color: ${addOpacity(Color.Blue, OpacityType.Hover)};
    }

    &:active {
      background-color: ${addOpacity(Color.Blue, OpacityType.Active)};
    }

    &:disabled {
      color: ${Color.DarkGray1};
    }
  `,
  [Color.Hallon1]: css`
    color: ${Color.Hallon1};

    &:hover {
      background-color: ${addOpacity(Color.Hallon1, OpacityType.Hover)};
    }

    &:active {
      background-color: ${addOpacity(Color.Hallon1, OpacityType.Active)};
    }

    &:disabled {
      color: ${Color.DarkGray1};
    }
  `,
  [Color.White]: css`
    color: ${Color.White};

    &:hover {
      background-color: ${addOpacity(Color.White, OpacityType.Hover)};
    }

    &:active {
      background-color: ${addOpacity(Color.White, OpacityType.Active)};
    }

    &:disabled {
      color: ${Color.DarkGray1};
    }
  `,
};
export const getColorVariant = (colorVariant: Color) => {
  switch (colorVariant) {
    case Color.Hallon1:
      return 'variantHallon1';
    case Color.White:
      return 'variantWhite';
    case Color.Blue:
    default:
      return 'variantBlue';
  }
};
