import Divider from '@/newComponents/Divider/Divider';
import SvgIcon from '@/newComponents/SvgIcon/SvgIcon';
import { CSSProperties, useMemo } from 'react';
import classNames from 'classnames';
import { Color, spacing } from '@/global/style/variables';
import { ExtendedLinkButtonProps, getColorVariant } from '@/components/LinkLookingButton/LinkLookingButton.types';
import styles from './LinkLookingButton.module.css';

const LinkLookingButton = ({
  text,
  'data-testid': dataTestId,
  colorVariant: _colorVariant = Color.Blue,
  iconKey,
  leftIcon = false,
  textAlign = 'center',
  alignSelf = 'auto',
  fontSize = '1.6rem',
  className,
  ...props
}: ExtendedLinkButtonProps) => {
  const style = useMemo(
    () => ({
      fontSize,
      textAlign,
      alignSelf,
    }),
    [fontSize, textAlign, alignSelf]
  ) as CSSProperties;
  const colorVariant = getColorVariant(_colorVariant);

  return (
    <button
      className={classNames(className, styles.linkButton, styles[colorVariant])}
      style={style}
      type="button"
      data-testid={dataTestId}
      {...props}
    >
      {iconKey && leftIcon && (
        <>
          <SvgIcon className={classNames(styles.icon, styles.leftIcon)} size="auto" icon={iconKey} />
          <Divider width={spacing.x05} />
        </>
      )}
      <span>{text}</span>
      {iconKey && !leftIcon && <SvgIcon className={styles.icon} icon={iconKey} />}
    </button>
  );
};

export default LinkLookingButton;
